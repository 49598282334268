<template>
	<div class="login_container">
		<div class="login_box">
			<div class="avatar_box">
				<img src="../assets/bestlogo.jpg" alt="">
			</div>
			<el-form ref='loginFormRef' :model="loginFrom" :rules="loginFromRules" label-width="0px" class="login_form">
				<!-- 用户名 -->
			  <el-form-item prop="username">
			    <el-input v-model="loginFrom.username" prefix-icon="el-icon-user-solid"></el-input>
			  </el-form-item>
			  <!-- 密码 -->
			  <el-form-item prop="password">
			    <el-input v-model="loginFrom.password" prefix-icon="el-icon-lock" type="password"></el-input>
			  </el-form-item>
			  <!-- 提交 -->
			  <el-form-item class="btns">
			    <el-button type="primary" @click='login'>登录</el-button>
			    <el-button type="info" @click='resetLoginFrom'>重置</el-button>
			  </el-form-item>
			  
			</el-form>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loginFrom:{
					username:'admin',
					password:'admin',
					grant_type:'password',
					client_id:'2',
					client_secret:'kHzde7WWlET33KQ75kQwp5NwqJejSj813PRadUhA',
					scope:'*'
				},
				loginFromRules:{
					username:[
						{ required: true, message: '请输入登录名称', trigger: 'blur' },
						//{ min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
					],
					password:[
						 { required: true, message: '请输入登录密码', trigger: 'blur' },
						//{ min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
					]
				}
			}
		},
		methods:{
			resetLoginFrom(){
				this.$refs.loginFormRef.resetFields();
			},
			login(){
				this.$refs.loginFormRef.validate(async valid => {
					//console.log(valid);
					if(!valid) retrun;
					const result = await this.$http.post('login',this.loginFrom);
					console.log(result.data);
					if(result.status !== 201) return this.$message.error('登录失败!');
					this.$message.success('登录成功!');
					window.sessionStorage.setItem("token",result.data.access_token);
					this.$router.push('/home');
					// const {data:result} = await this.$http.post('login',this.loginFrom);
					// if(res.meta.status !== 200) return console.log("登录失败");
					// console.log("登录成功");
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.login_container{
		background-color: #2b4b6b;
		height: 100%;
	}
	.login_box{
		width: 450px;
		height: 300px;
		background-color: #fff;
		border-radius: 3px;
		position: absolute;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
		.avatar_box{
			height:30%;
			width: 80%;
			//border: 1px solid #eee;
			//border-radius: 50%;
			padding: 10px;
			//box-shadow: 0 0 10px #ddd;
			position: absolute;
			left: 50%;
			transform: translate(-50%,10%);
			background-color: #fff;
			img{
				width: 100%;
				height: 100%;
				//border-radius: 50%;
				background-color: #eee;
			}
			
		}
		
		
	}
	.login_form{
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}
	.btns{
		display: flex;
		justify-content: flex-end;
	}
</style>
<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>门点成本列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			
			<el-table :data="mendianlist" border stripe>
				<el-table-column label="#" type="index"></el-table-column>
				<el-table-column label="邮编" prop="zip"></el-table-column>
				<el-table-column label="国家" prop="country"></el-table-column>
				<el-table-column label="城市" prop="city"></el-table-column>
				<el-table-column label="货运类型" prop="type_traffic"></el-table-column>
				<el-table-column label="进出口" prop="type_jinchukou"></el-table-column>
				<el-table-column label="日期" prop="updated_at"></el-table-column>
				<el-table-column label="操作" width="80px">
					<template slot-scope="scope">
						<el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
						      <el-button type="primary" icon="el-icon-edit" size="mini"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
	</div>
	
</template>

<script>
	export default{
		data(){
			return{
				queryInfo:{
					type_traffic:'海运整箱'
				},
				mendianlist:[]
			}
		},
		created() {
			this.getUserList()
		},
		methods:{
			async getUserList(){
			const {data:res} = await this.$http.get('inquiryform',{params:this.queryInfo})
			// if(res.meta.status !== 200) return this.$message.error("获取信息失败！")
			this.mendianlist = res
			// console.log(res)
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '#' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>报价单号查询</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="7">
					<el-input placeholder="请输入报价单号" v-model="queryInfo.pdf_code" clearable>
					  <el-button slot="append" icon="el-icon-search" @click='getPdfList'></el-button>
					</el-input>
				</el-col>
				<el-col :span="4"></el-col>
			</el-row>
			<el-table :data="pdfdwonlist" border stripe>
				<el-table-column label="#" type="index"></el-table-column>
				<el-table-column label="PDF_CODE" prop="pdf_code">
					<template slot-scope="scope">
						 <el-tooltip effect="dark" content="点击进入下载页面" placement="top" :enterable="false">
						      <el-link :href="scope.row.pdf_url" type="primary" target="_blank">{{scope.row.pdf_code}}</el-link>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column label="SERVICE_MODE" prop="service_mode"></el-table-column>
			</el-table>
		</el-card>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				queryInfo:{
					//cost_record_1609143060
					pdf_code:''
				},
				pdfdwonlist:[]
			}
		},
		created(){
			//this.getPdfList()
		},
		methods:{
			async getPdfList(){
			const {data:res} = await this.$http.get('Record/searchquopdf',{params:this.queryInfo})
			// if(res.meta.status !== 200) return this.$message.error("获取信息失败！")
			this.pdfdwonlist = res.quodata
			console.log(res)
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
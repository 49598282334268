import Vue from 'vue'
import { Button } from 'element-ui'
import {Form, FormItem} from 'element-ui'
import {Input} from 'element-ui'
import {
Message,Container,Header,Aside,Main,Menu,Submenu,
MenuItemGroup,MenuItem,Breadcrumb,BreadcrumbItem,Card,
Row,Col,Table,TableColumn,Tooltip,Select,Option,RadioGroup,
Radio,DatePicker,TimePicker,Link,Divider} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Link)
Vue.use(Divider)
Vue.prototype.$message = Message

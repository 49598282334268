<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>门点成本查询</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-form label-position="left" :model="findmdpam" :rules="findmdpamrules" ref="findmdRef" label-width="150px">
			  <el-form-item label="国家COUNTRY" prop="country">
			  <el-select v-model="findmdpam.country" filterable placeholder="请选择国家">
			     <el-option v-for="item in countries" :key="item.country" :label="item.country" :value="item.country" v-if="item.country !==null"></el-option>
			  </el-select>
			  </el-form-item>
			  <el-form-item label="城市CITY" prop="city">
			      <el-select v-model="findmdpam.city" filterable placeholder="请选择城市">
			        <el-option v-for="item in countries" :key="item.city" :label="item.city" :value="item.city" v-if="item.city !==null"></el-option>
			      </el-select>
			  </el-form-item>
			  <el-form-item label="邮编" prop="zip">
			      <el-input v-model="findmdpam.zip"></el-input>
			  </el-form-item>
			  <el-form-item label="进口/出口" prop="type_jinchukou">
			      <el-radio-group v-model="findmdpam.type_jinchukou">
			        <el-radio label="进口"></el-radio>
			        <el-radio label="出口"></el-radio>
			      </el-radio-group>
			  </el-form-item>
			  <el-form-item label="运输类型" prop="type_traffic">
			      <el-radio-group v-model="findmdpam.type_traffic">
			        <el-radio label="海运整箱" @change='show=true'></el-radio>
			        <el-radio label="海运拼箱" @change='show=false'></el-radio>
					<el-radio label="空运" @change='show=false'></el-radio>
			      </el-radio-group>
			  </el-form-item>
			  <el-form-item v-show="show" label="箱型" prop="box_pile">
			      <el-select v-model="findmdpam.box_pile" filterable placeholder="请选择箱型">
			        <el-option v-for="item in box_piles" :key="item.value" :label="item.label" :value="item.value"></el-option>
			      </el-select>
			  </el-form-item>
			  <el-form-item label="时间段">
			      <el-date-picker
			            v-model="findmdpam.time"
			            type="daterange"
			            align="left"
			            unlink-panels
			            range-separator="至"
			            start-placeholder="开始日期"
			            end-placeholder="结束日期"
			            :picker-options="pickerOptions">
			          </el-date-picker>
			   </el-form-item>
			   <el-form-item>
			       <el-button type="primary" @click='find'>查询</el-button>
			       <el-button type="info" @click='resetFindFrom'>重置</el-button>
			     </el-form-item>
			</el-form>
		</el-card>
	</div>
	
</template>

<script>
	export default{
		data(){
			return{
				show:false,
				queryInfo:{
					type_traffic:'海运整箱'
				},
				countries:[
					{
						country:'',city:''
					}
				],
				box_piles :[
					{value:'20',label:'20'},
					{value:'40',label:'40'}
				],
				mendianlist:[],
				findmdpam:{
					country:'',
					city:'',
					zip:'',
					type_jinchukou:'',
					type_traffic:'',
					box_pile:'',
					time:''
				},
				findmdpamrules:{
					country:[
						{ required: true, message: '请选择国家', trigger: 'blur' }
					],
					city:[
						{ required: true, message: '请选择城市', trigger: 'blur' }
					],
					type_jinchukou:[
						{ required: true, message: '请选择进口方式', trigger: 'blur' }
					],
					type_traffic:[
						{ required: true, message: '请选择运输方式', trigger: 'blur' }
					]
				},
				pickerOptions: {
				          shortcuts: [{
				            text: '最近一周',
				            onClick(picker) {
				              const end = new Date();
				              const start = new Date();
				              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				              picker.$emit('pick', [start, end]);
				            }
				          }, {
				            text: '最近一个月',
				            onClick(picker) {
				              const end = new Date();
				              const start = new Date();
				              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				              picker.$emit('pick', [start, end]);
				            }
				          }, {
				            text: '最近三个月',
				            onClick(picker) {
				              const end = new Date();
				              const start = new Date();
				              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				              picker.$emit('pick', [start, end]);
				            }
				          }]
				        },
				        value1: '',
				        value2: ''
			}
		},
		created() {
			this.getCountryList()
		},
		methods:{
			resetFindFrom(){
				this.$refs.findmdRef.resetFields();
			},
			async getCountryList(){
				let countries = {};
				const {data:res} = await this.$http.get('citys')
				this.countries = res
				console.log(res)
			},
			find(){}
			// async getUserList(){
			// const {data:res} = await this.$http.get('inquiryform',{params:this.queryInfo})
			// // if(res.meta.status !== 200) return this.$message.error("获取信息失败！")
			// this.mendianlist = res
			// // console.log(res)
			// }
		}
	}
</script>

<style lang="less" scoped>
	.el-form{
		width: 31%;
	}
</style>
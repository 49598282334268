<template>
	<el-container class='home-container'>
		<el-header>
			<div>
				<img src="../assets/bestlogo.jpg" alt="">
				<span>报价查询前台系统</span>
			</div>
			<el-button type="info" @click='logout'>退出</el-button>
		</el-header>
		<el-container>
			<el-aside width="200px">
				<el-menu background-color="#333744" text-color="#fff" active-text-color="#409eff" router :default-active="activePath">
					<el-menu-item index="/quote" @click="saveNavState('/quote')">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>报价查询</span>
						</template>
					</el-menu-item>
					<el-menu-item  index="/enquiry" @click="saveNavState('/enquiry')">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>门点成本查询</span>
						</template>
					</el-menu-item>
					<el-menu-item  index="/create" @click="saveNavState('/create')">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>报价单制作</span>
						</template>
					</el-menu-item>
					<el-menu-item  index="/search" @click="saveNavState('/search')">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>报价单号查询</span>
						</template>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	export default {
		data(){
			return {
				//被激活的链接地址
				activePath:''
			}
		},
		created(){
			this.activePath=window.sessionStorage.getItem('activePath')
		},
		methods: {
			logout() {
				window.sessionStorage.clear()
				this.$router.push('/login')
			},
			//保存链接的激活状态
			saveNavState(activePath){
				window.sessionStorage.setItem('activePath',activePath)
			}
		}
	};
</script>

<style lang="less" scoped>
	.home-container {
		height: 100%;
	}

	.el-header {
		background-color: #373d41;
		display: flex;
		justify-content: space-between;
		padding-left: 0;
		align-items: center;
		color: #fff;
		font-size: 20px;

		> div {
			display: flex;
			align-items: center;
			img{
				width: 200px;
			}
			span {
				margin-left: 15px;
			}
		}
	}

	.el-aside {
		background-color: #333744;
		.el-menu{
			border-right: none;
		}
	}

	.el-main {
		background-color: #eaedf1;
	}
	.toggle-button{
		background-color: #4A5064;
		font-size: 10px;
		line-height: 24px;
		color: #fff;
		text-align: center;
		letter-spacing: 0.2em;
		cursor: pointer;
	}
</style>

<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>门点成本查询</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-form label-position="left" :model="findmdpam" :rules="findmdpamrules" ref="findmdRef" label-width="200px">
			  <el-form-item label="运输类型" prop="type_traffic">
			      <el-radio-group v-model="findmdpam.type_traffic">
			        <el-radio label="海运整箱" @change="optiontra('海运整箱')"></el-radio>
			        <el-radio label="海运拼箱" @change="optiontra('海运拼箱')"></el-radio>
			  		<el-radio label="空运" @change="optiontra('空运')"></el-radio>
			      </el-radio-group>
			  </el-form-item>
			  <el-form-item label="ORINGINAL 启运港" prop="country">
			  <el-select v-model="findmdpam.country" filterable placeholder="请选择启运港">
			     <el-option v-for="item in countries" :key="item.country" :label="item.country" :value="item.country" v-if="item.country !==null"></el-option>
			  </el-select>
			  </el-form-item>
			  <el-form-item label="DESTINATION 目的地" prop="city">
			      <el-select v-model="findmdpam.city" filterable placeholder="请选择目的地">
			        <el-option v-for="item in countries" :key="item.city" :label="item.city" :value="item.city" v-if="item.city !==null"></el-option>
			      </el-select>
			  </el-form-item>
			  <el-form-item v-show="box_show" label="海运箱型">
			      <el-select v-model="findmdpam.box_pile" multiple placeholder="请选择海运箱型">
			        <el-option v-for="item in box_piles" :key="item.value" :label="item.label" :value="item.value"></el-option>
			      </el-select>
			  </el-form-item>
			  
			  <el-form-item v-show="air_show" label="空运货型">
			  	<el-select v-model="findmdpam.wight_class" multiple placeholder="请选择空运货型">
			  	    <el-option
			  	      v-for="item in air_piles"
			  	      :key="item.value"
			  	      :label="item.label"
			  	      :value="item.value">
			  	    </el-option>
			  	  </el-select>			  
			  </el-form-item>
			  
			   <el-form-item>
			       <el-button type="primary" @click='find'>查询</el-button>
			       <el-button type="info" @click='resetFindFrom'>重置</el-button>
			     </el-form-item>
			</el-form>
		</el-card>
	</div>
	
</template>

<script>
	export default{
		data(){
			return{
				box_show:false,
				air_show:false,
				box_piles:[
					{value:'20GP',label:'20GP'},
					{value:'40GP',label:'40GP'}
				],
				air_piles:[
					{value:'45kgs',label:'45kgs'},
					{value:'100kgs',label:'100kgs'},
					{value:'300kgs',label:'300kgs'},
					{value:'500kgs',label:'500kgs'},
					{value:'1000kgs',label:'1000kgs'}
				],
				queryInfo:{
					type_traffic:'海运整箱'
				},
				countries:[
					{
						country:'',city:''
					}
				],
				mendianlist:[],
				findmdpam:{
					country:'',
					city:'',
					zip:'',
					type_jinchukou:'',
					type_traffic:'',
					box_pile:'',
					time:'',
					wight_class:''
				},
				findmdpamrules:{
					country:[
						{ required: true, message: '请选择国家', trigger: 'blur' }
					],
					city:[
						{ required: true, message: '请选择城市', trigger: 'blur' }
					],
					type_traffic:[
						{ required: true, message: '请选择运输方式', trigger: 'blur' }
					]
				}
				        
			}
		},
		created() {
			this.getCountryList()
		},
		methods:{
			resetFindFrom(){
				this.$refs.findmdRef.resetFields();
			},
			async getCountryList(){
				let countries = {};
				const {data:res} = await this.$http.get('citys')
				this.countries = res
				console.log(res)
			},
			find(){},
			async optiontra(op){
				if(op==='海运整箱'){
					this.box_show = true
					const {data:res} = await this.$http.get('citys')
				}else{
					this.box_show = false
				}
				
				if(op==='空运'){
					this.air_show = true
					const {data:res} = await this.$http.get('citys')
				}else{
					this.air_show = false
				}
			}
			// async getUserList(){
			// const {data:res} = await this.$http.get('inquiryform',{params:this.queryInfo})
			// // if(res.meta.status !== 200) return this.$message.error("获取信息失败！")
			// this.mendianlist = res
			// // console.log(res)
			// }
		}
	}
</script>

<style lang="less" scoped>
	.el-form{
		width: 31%;
	}
</style>
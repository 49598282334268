<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>报价单制作</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
				<el-form :model="initial" ref="initialFormRef" label-width="200px" class="demo-ruleForm">
					<el-row>
						<el-col :span="10">
						  <el-form-item label="POL:" prop="pol">
							<el-input v-model="initial.pol"></el-input>
						  </el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
						  <el-form-item label="POD:" prop="pod">
							<el-input v-model="initial.pod"></el-input>
						  </el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
							<el-form-item label="SERVICE MODE:" prop="service_mode">
								<el-select v-model="initial.service_mode" placeholder="请选择">
								    <el-option
								      v-for="item in service_modeoption"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value">
								    </el-option>
								  </el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
							<el-form-item label="QUOTE DATE:" prop="quoteDate">
								<el-date-picker
								      v-model="initial.quoteDate"
								      type="date"
								      placeholder="选择日期">
								    </el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="ACTIVE DATE:" prop="activedate">
								<el-date-picker
								      v-model="initial.activedate"
								      type="date"
								      placeholder="选择日期">
								    </el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EXPIRE DATE:" prop="expiredate">
								<el-date-picker
								      v-model="initial.expiredate"
								      type="date"
								      placeholder="选择日期">
								    </el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
							<el-form-item label="PRINT DATE:" prop="printdate">
								<el-date-picker
								      v-model="initial.printdate"
								      type="date"
								      placeholder="选择日期">
								    </el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="10">
						  <el-form-item label="POSTCODE:" prop="postcode">
							<el-input v-model="initial.postcode"></el-input>
						  </el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="7">
						  <el-form-item label="TRANSIT TYPE:" prop="transitType">
							<el-input v-model="initial.transitType"></el-input>
						  </el-form-item>
						</el-col>
						<el-col :span="7">
						  <el-form-item label="FREIGHT TERM:" prop="freightTerm">
							<el-input v-model="initial.freightTerm"></el-input>
						  </el-form-item>
						</el-col>
					</el-row>
				</el-form>
				
				<el-row>
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					  <el-menu-item index="1">CARGO DETAILS</el-menu-item>
					</el-menu>
					<el-col>
						<el-button type="primary" v-on:click.prevent="addNewTodo">新增</el-button>	 
					</el-col>
					<el-col>
						<el-table :data="cargoDetails" class="tb-edit" border style="width: 100%;margin:0 auto;">
						      <el-table-column
								prop="carrier"
						        label="CARRIER"
						        width="180">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.carrier" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
						      </el-table-column>
						      <el-table-column
						        prop="fee"
						        label="OCEAN FEE/AIR FREIGHT"
						        width="180">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.fee" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
						      </el-table-column>
						      <el-table-column
						        prop="size"
						        label="CONTAINER SIZE WIGHT CLASS">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.size" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
						      </el-table-column>
							  <el-table-column
							    prop="qty"
							    label="QTY">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.qty" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column>
							  <el-table-column
							    prop="etd"
							    label="ETD">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.etd" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column>
							  <el-table-column
							    prop="transit"
							    label="TRANSIT">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.transit" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column>
							  <el-table-column
							    prop="currency"
							    label="CURRENCY">
								<template scope="scope">
									<el-select v-model="scope.row.currency" placeholder="请选择币种">
									  <el-option v-for="item in currencys" :key="item.id" :label="item.code" :value="item.code" v-if="item.code !==null"></el-option>
									</el-select>
								</template>
							  </el-table-column>
							  <!-- <el-table-column
							    prop="total"
							    label="TOTAL">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.total" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column> -->
							 <el-table-column
							   prop="total"
							   label="TOTAL">
							 	<template scope="scope">
							 		<el-input size="small" :value="scope.row.fee * scope.row.size" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
							 	</template>
							 </el-table-column>
							  <el-table-column
							        fixed="right"
							        label="操作"
							        width="120">
							        <template slot-scope="scope">
							          <el-button
							            @click.native.prevent="deleteRow(scope.$index, cargoDetails)"
							            type="text"
							            size="small">
							            移除
							          </el-button>
							        </template>
							      </el-table-column>
						    </el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					  <el-menu-item index="1">EXTRA CHARGES</el-menu-item>
					</el-menu>
					<el-col>
						<el-button type="primary" v-on:click.prevent="addNewExtracharges">新增</el-button>	 
					</el-col>
					<el-col>
						<el-table :data="extracharges" class="tb-edit" border style="width: 100%;margin:0 auto;">
						      <el-table-column
								prop="name"
						        label="NAME"
						        width="180">
								<template scope="scope">
								     <!-- <el-input size="small" v-model="scope.row.name" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input> -->
										<el-select v-model="scope.row.name" placeholder="请选择费用明细名称">
										  <el-option v-for="item in costnames" :key="item.id" :label="item.text" :value="item.text" v-if="item.text !==null"></el-option>
										</el-select>
								</template>
						      </el-table-column>
						      <el-table-column
						        prop="rmb"
						        label="RMB"
						        width="180">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.rmb" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
						      </el-table-column>
						      <el-table-column
						        prop="usd"
						        label="USD">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.usd" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
						      </el-table-column>
							  <el-table-column
							    prop="qty"
							    label="QTY">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.qty" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column>
							  <el-table-column
							    prop="total"
							    label="TOTAL">
								<template scope="scope">
								     <el-input size="small" v-model="scope.row.total" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
								</template>
							  </el-table-column>
							 <el-table-column
							   prop="total"
							   label="TOTAL">
							 	<template scope="scope">
							 		<el-input size="small" :value="scope.row.usd * scope.row.qty" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
							 	</template>
							 </el-table-column>
							  <el-table-column
							        fixed="right"
							        label="操作"
							        width="120">
							        <template slot-scope="scope">
							          <el-button
							            @click.native.prevent="deleteRow(scope.$index, extracharges)"
							            type="text"
							            size="small">
							            移除
							          </el-button>
							        </template>
							      </el-table-column>
						    </el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					  <el-menu-item index="1">TOTAL</el-menu-item>
					</el-menu>
					<el-col>
						<template>
						  <div>
						    <span>{{total.usdtotal}}</span>
						    <el-divider direction="vertical"></el-divider>
						    <span>{{total.rmbtotal}}</span>
						  </div>
						</template>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
						  <el-menu-item index="1">REMARK</el-menu-item>
						</el-menu>
						<el-rol>
								<el-input
								  type="textarea"
								  :rows="2"
								  placeholder="请输入内容"
								  v-model="initial.remark">
								</el-input>
				
						</el-rol>
						
					</el-col>
				</el-row>
				<el-row>
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					  <el-menu-item index="1">操作</el-menu-item>
					</el-menu>
					<el-col>
						<el-button type="primary" @click='exportpdf'>导出pdf<i class="el-icon-upload el-icon--right"></i></el-button>
					</el-col>
				</el-row>
		</el-card>
	</div>
</template>

<script>
	export default{
		data(){
			return{ 
				service_modeoption:[
					{value:'空运',label:'空运'},
					{value:'海运拼箱',label:'海运拼箱'},
					{value:'海运整箱',label:'海运整箱'}
				],
				initial:{
					pol:'',
					pod:'',
					service_mode:'',
					postcode:'',
					quoteDate:'',
					activedate:'',
					expiredate:'',
					transitType:'DOOR TO DOOR',
					freightTerm:'FREIGHT TERM',
					remark:''
				},
				cargoDetails: [
							{
								carrier: "",
								fee: 1,
								size: 2,
								qty:'',
								etd:'',
								transit:'',
								currency:'',
								total:'',
							}
							
				],
				costnames:[
					{
						id:'',
						text:''
					}
				],
				currencys:[
					{
						id:'',
						code:''
					}
				],
				extracharges:[
					{
						name: "",
						rmb:1,
						usd:2,
						qty:3,
						total:""
					}
				],
				total:{
					usdtotal:0,
					rmbtotal:0
				},
				remark:''
			}
		},
		watch:{
			//cargoDetails.carrier
		},
		mounted(){
		
		},    
		computed:{
			sum(index){
				//this.cargoDetails=response.data.data;
			console.log(this.cargoDetails.total);
			this.cargoDetails.total = parseFloat(this.cargoDetails.fee) * parseFloat(this.cargoDetails.size)
				return parseFloat(this.cargoDetails.fee) * parseFloat(this.cargoDetails.size) || 0
			}
		  },
		  created() {
		  	this.getCostnamesList(),
			this.getCurrencysList()
		  },
		methods:{	
			addNewTodo: function () {
			      this.cargoDetails.push({
			        id: this.nextTodoId++,
			        title: this.newTodoText
			      })
			      this.newTodoText = ''
			},
			deleteRow(index, rows) {
				        rows.splice(index, 1);
			},
			addNewExtracharges:function(){
				this.extracharges.push({
				  id: this.nextTodoId++,
				  title: this.newTodoText
				})
				this.newTodoText = ''
			},
			async getCostnamesList(){
				let costnames = {};
				const {data:res} = await this.$http.get('expenseItems')
				this.costnames = res
				//console.log(res)
			},
			async getCurrencysList(){
				let currencys = {};
				const {data:res} = await this.$http.get('findCurrency')
				this.currencys = res
				//console.log(res)
			},
			exportpdf(){
				this.$refs.initialFormRef.validate(async valid => {
					//console.log(valid);
					if(!valid) retrun;
					// conso
					var form = this.initial;
					form.cargo_details = this.cargoDetails
					form.total = this.total
					form.extra_charges = this.extracharges
					const result = await this.$http.post('quosave/down',form);
					//console.log(result.data.url);
					window.open(result.data.url,'_blank') // 在新窗口打开外链接
					//window.location.href =result.data.url;
				});
			}
		}
	}
</script>

<style lang="less" scoped>
</style>
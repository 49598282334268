import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import Quote from '../components/Quote.vue'
import Enquiry from '../components/Enquiry.vue'
import Create from '../components/Create.vue'
import Search from '../components/Search.vue'
import Enquirylist from '../components/Enquirylist.vue'

Vue.use(VueRouter)

const routes = [
	{ path: '/', redirect: '/login'},
	{ path: '/login', component: Login},
	{ path: '/home', component: Home, redirect: '/quote', children:[
		{path:'/welcome', component:Welcome},
		{path:'/quote', component:Quote},
		{path:'/enquiry', component:Enquiry},
		{path:'/enquirylist', component:Enquirylist},
		{path:'/create', component:Create},
		{path:'/search', component:Search}
		],
	}
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

//挂载路由导航守卫
router.beforeEach((to,from,next)=>{
	if(to.path==='/login') return next()
	//获取taoken
	const tokenStr = window.sessionStorage.getItem('token')
	if(!tokenStr) return next('/login')
	next()
	
})

export default router
